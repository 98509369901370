import React from "react";
import Slider from "react-slick";

import { Container, Row, Col, Card } from "reactstrap";

function Sponsor() {
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;
  const settings = {
    dots: true,
    autoplay: true,
    arrows: size,
    autoplaySpeed: 8000,
    slidesToShow: 3,
    slidesToScroll: 1,
    className: "p-10",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  return (
    <>
      <div
        style={{
          backgroundColor: "transparent",
        }}
        className="section section-with-space"
        data-parallax={true}
      >
        <Container>
          <h1
            className="text-center text-g mb-4"
            style={{
              fontSize: "2rem",
            }}
          >
            PREVIOUS EDITIONS SPONSORS & PARTNERS
          </h1>
          <Row className="justify-content-center">
            <Col lg={11} className={"ml-auto mr-auto mb-4"}>
              <Slider {...settings}>
                {sponsors.map((s, index) => (
                  <a href={s.link} className="px-3">
                    <Card style={{ borderRadius: 0, maxWidth: "350px" }} className="mx-auto">
                      <img
                        src={require(`assets/sponsors/${s.image}`)}
                        className="mx-auto"
                        alt={s.name}
                        width="100%"
                        style={{ borderRadius: 0 }}
                      />
                    </Card>
                    {/* <h4 className="text-center text-400 text-dark pb-2">{s.type}</h4> */}
                  </a>
                ))}
              </Slider>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Sponsor;
export const sponsors = [
  {
    name: "ESRI",
    pageLink: "ESRI",
    type: "GIS PARTNER",
    image: "esri.png",
    text: [""],
    link: "https://www.esri.com/en-us/digital-twin/overview",
    lg: 6,
  },

  {
    name: "Invicara",
    pageLink: "Autodesk",
    type: "SILVER SPONSOR",
    image: "1682489560477-invicara_owler_20160227_200730_original.png",
    text: [""],
    link: "",
    lg: 6,
  },
  {
    name: "Cohesive",
    pageLink: "Cohesive",
    type: "SILVER SPONSOR",
    image: "1681434192347-Cohesive_Logo.png",
    text: [""],
    link: "",
    lg: 6,
  },
  {
    name: "Autodesk",
    pageLink: "Autodesk",
    type: "DIGITAL TWIN PARTNER",
    image: "autodesk.png",
    text: [""],
    link: "https://www.autodesk.ae/",
    lg: 6,
  },
  {
    name: "dassault",
    pageLink: "dassault",
    type: "VIRTUAL TWIN PARTNER",
    image: "dassault.png",
    text: [""],
    link: "https://ifwe.3ds.com/construction-cities-territories",
    lg: 6,
  },

  {
    name: "archdesk",
    pageLink: "archdesk",
    type: "GOLD SPONSOR",
    image: "archdesk.png",
    text: [""],
    link: "https://archdesk.com/",
    lg: 6,
  },
  {
    name: "Mtwo",
    pageLink: "Mtwo",
    type: "DIGITAL CONSTRUCTION PARTNER",
    image: "mtwo.png",
    text: [""],
    link: "https://www.softwareone.com/ar-ae/industries/mtwo-construction-cloud",
    lg: 6,
  },
  {
    name: "OMNIX Unity",
    pageLink: "OMNIX Unity",
    type: "EXHIBITOR",
    image: "omnix.jpg",
    text: [""],
    link: "https://omnix.com/engineering-services/",
    lg: 4,
  },
  {
    name: "LEICA ",
    pageLink: "LEICA ",
    type: "EXHIBITOR",
    image: "leica.jpg",
    text: [""],
    link: "https://www.leica-microsystems.com/",
    lg: 4,
  },
  {
    name: "facilio",
    pageLink: "facilio",
    type: "EXHIBITOR",
    image: "facilio.png",
    text: [""],
    link: "https://facilio.com",
    lg: 4,
  },
  {
    name: "360Disti",
    pageLink: "360Disti",
    type: "EXHIBITOR",
    image: "360.jpg",
    text: [""],
    link: "https://www.360disti.com/",
    lg: 4,
  },
  {
    name: "ZOHO IOT",
    pageLink: "ZOHO IOT",
    type: "EXHIBITOR",
    image: "zoho.png",
    text: [""],
    link: "https://www.zoho.com/iot/",
    lg: 4,
  },
  {
    name: "ASITE",
    pageLink: "ASITE",
    type: "DIGITAL PLATFORM PARTNER",
    image: "asite.jpg",
    text: [""],
    link: "https://www.asite.com/",
    lg: 4,
  },

  {
    name: "DUNCAN & ROSS",
    pageLink: "DUNCAN & ROSS",
    type: "EXHIBITOR ",
    image: "dr.png",
    text: [""],
    link: "https://www.duncan-ross.com/",
    lg: 4,
  },

  {
    name: "bsi",
    pageLink: "bsi",
    type: "WORKSHOP PARTNER",
    image: "bsi.png",
    text: [""],
    link: "https://www.bsigroup.com/",
    lg: 4,
  },
  {
    name: "PMIUAE",
    pageLink: "PMIUAE",
    type: "SUPPORTING ASSOCIATION",
    image: "PMIUA.jpg",
    text: [""],
    link: "https://pmiuae.org/en/",
    lg: 12,
  },
  {
    name: "Digital Twin",
    pageLink: "digitaltwin",
    type: "SUPPORTING PARTNER",
    image: "twin.png",
    text: [""],
    link: "https://www.digitaltwinconsortium.org/",
    lg: 12,
  },
  {
    name: "BIM 4 Turkey",
    pageLink: "bim4turkey",
    type: "SUPPORTING PARTNER",
    image: "bim4.png",
    text: [""],
    link: "https://bim4turkey.com/en",
    lg: 3,
  },
  {
    name: "RICS",
    pageLink: "RICS",
    type: "SUPPORTING PARTNER",
    image: "RICS.png",
    text: [""],
    link: "https://www.rics.org/en-in/",
    lg: 3,
  },
  {
    name: "CIOB",
    pageLink: "CIOB",
    type: "SUPPORTING PARTNER",
    image: "CIOB.png",
    text: [""],
    link: "https://www.ciob.org/",
    lg: 3,
  },
  // {
  //   name: "NOVA Hispanic American Chamber of Commerce",
  //   pageLink: "NOVA",
  //   type: "SUPPORTING PARTNER",
  //   image: "nova.png",
  //   text: [""],
  //   link: "https://www.novahispanicchamber.com/",
  //   lg: 3,
  // },
  {
    name: "Thevrara",
    pageLink: "Thevrara",
    type: "SUPPORTING PARTNER",
    image: "thevrara.png",
    text: [""],
    link: "https://www.thevrara.com/",
    lg: 3,
  },
  {
    name: "Heriot Watt University",
    pageLink: "heriotwatt",
    type: "KNOWLEDGE PARTNER",
    image: "Heriot.jpg",
    text: [""],
    link: "https://www.hw.ac.uk/dubai/research/smart-construction.htm",
    lg: 4,
  },
  {
    name: "soeuae",
    pageLink: "soeuae",
    type: "SUPPORTING PARTNER",
    image: "soeuae.jpg",
    text: [""],
    link: "https://www.soeuae.ae/ar/default.aspx",
    lg: 3,
  },

  {
    name: "itech",
    pageLink: "itech",
    type: "VISITING PARTNER",
    image: "itech.jpg",
    text: [""],
    link: "http://itechholding.com/uae/",
    lg: 3,
  },
];
